<template>
  <section class="catch-all">
    <div class="catch-all___container">
      <strong class="catch-all__error">404</strong>
      <br />
      <strong class="catch-all__error-message">Strona nie została odnaleziona</strong>
      <p class="catch-all__paragraph">
        Strona o adresie {{ location.pathname }} nie została znaleziona, wróc na stronę
        główną CrossFit Black Star i szukaj dalej! :)
      </p>
      <base-button classes="dark-btn" type="router-link" to="/">
        Strona Główna
      </base-button>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      location: location,
    };
  },
  beforeRouteLeave(_, __, next) {
    if (this.$store.getters.isPhoneMenuOpen) {
      this.$store.commit("openClosePhoneMenu");
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "./scss/catchAll.scss";
</style>
